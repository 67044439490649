


































































































































































    .memberClub {
        .diglogPStyle {
            margin: 0.2rem 0.2rem 0.2rem 2rem;
			font-size: 16px;
        }
        .dialogStyle {
            margin: 0.4rem 0.4rem 0.1rem 0.4rem;
            padding:0 0 0.2rem;
            border-bottom: 1px solid #f3f0f3;
        }
        .diglogPStyle1{
            font-size: 16px;
            // margin: 0.4rem 0.4rem 0.1rem 0.4rem;
			margin: .3rem .4rem;
            // padding: 0 0 0.2rem;
			display: flex;
			.label {
				// width: 5em;
				width: 9em;
			}
			.psdInput {
				font-size: 16px;
				border: 0;
				flex: 1;
				display: block;
				width: 100%;
			}
        }

        .buttonOk{
            margin-top: 4.5rem;
            color: #FFF;
			font-size: .32rem;
            border: 0;
            // border-radius: 5px;
            width: 100%;
			height: .88rem;
			line-height: .88rem;
			background: #90C4FF;
			border-radius: .12rem;
        }
        .userInfo {
            border-bottom: 0.12rem solid #F5F5F5;
            overflow: hidden;
            padding: 0.2rem 0.5rem;
            position: relative;
			.level {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: -.2rem;
				img {
					width: .4rem;
					height: .4rem;
					display: block;
					margin-right: -.25rem;
					position: relative;
					z-index: 2;
				}
				span {
					color: #FFFFFF;
					font-size: .12rem;
					width: 1rem;
					height: .24rem;
					line-height: .24rem;
					padding-left: .3rem;
					background: linear-gradient(139deg, #BBDDFF 0%, #AFC2FF 100%);
					border: 1px solid #FFFFFF;
					border-radius: .16rem;
					display: block;
					position: relative;
					z-index: 1;
				}
			}

            .userHead {
                border-radius: 50%;
                width: 1.2rem;
                height: 1.2rem;
                display: block;
            }

            .userName {
                font-size: 0.36rem;
				font-family: Bahnschrift;
				line-height: .5rem;
                // font-weight: bold;
				padding-top: .15rem;
            }

            .vipBox {
                margin-top: 0.1rem;


                .vipLevel {
                    width: 1rem;
                    height: 0.4rem;
                    position: relative;
                    font-size: 0.24rem;
                    font-family: Bahnschrift;
                    display: inline-block;
                    color: #C1581B;

                    img {
                        position: absolute;
                        left: 0;
                        top: 0;
                        display: block;
                        width: 100%;
                        height: 100%;
                    }

                    span {
                        position: relative;
                        z-index: 11;
                        display: block;
                        text-align: center;
                        line-height: 0.4rem;
                        font-weight: bold;
                        font-size: 0.26rem;
                    }
                }

                .expireDay {
                    // display: inline-block;
					color: #666666;
					line-height: .35rem;

                    .span1 {
                        font-size: 0.24rem;
                        font-family: Bahnschrift;
                        font-weight: normal;
                    }

                    .span2 {
                        margin-left: 0.1rem;
                        font-size: 0.24rem;
                        // font-weight: 500;
                        // color: #737373;
                        // opacity: 0.7;
                    }
                }
            }
        }

        .banli {
            padding: 0.5rem 0.3rem;

            .mcTitle {
                font-size: 0.32rem;
				line-height: .45rem;
                // font-weight: 500;
                color: #373737;
				font-weight: bold;
				margin-bottom: .25rem;
            }
            .changeStyles{
                border: 1px solid #94C7FC;
                border-radius: .12rem;
                text-align: center;
                padding: 0.35rem 0;
				overflow: hidden;
				&.checked {
					color: #fff;
					background-image: linear-gradient(135deg, rgba(148, 199, 252, 1) 0%, rgba(235, 226, 254, 1) 100%);
					position: relative;
					&::after {
						content: '';
						position: absolute;
						right: 0;
						bottom: 0;
						width: .6rem;
						height: .6rem;
						background: url(../../assets/imgs/icon/member_icon_bottom.png) no-repeat right bottom;
						background-size: contain;
					}
					.dataTitle, 
					.dataPrice, 
					.dataPriceOld {
						color: #FFFFFF;
					}
				}
            }
            .mainBackColor1 p{
                color: #fff;
            }
            .dataTitle{
				color: #666666;
                font-size: 0.28rem;
                // font-weight: bold;
                // color: #000000;
            }
            .dataPrice{
				color: #90C4FF;
                font-size: 0.44rem;
                // font-weight: bold;
				font-family: Bahnschrift;
				margin-top: 0.35rem;
            }
            .dataPriceOld{
				color: #999999;
                font-size: 0.32rem;
                // font-weight: 500;
				font-family: Bahnschrift;
                text-decoration: line-through;
				margin-top: 0.2rem;
            }
        }
    }

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;