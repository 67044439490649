.memberClub .diglogPStyle {
  margin: 0.2rem 0.2rem 0.2rem 2rem;
  font-size: 16px;
}
.memberClub .dialogStyle {
  margin: 0.4rem 0.4rem 0.1rem 0.4rem;
  padding: 0 0 0.2rem;
  border-bottom: 1px solid #f3f0f3;
}
.memberClub .diglogPStyle1 {
  font-size: 16px;
  margin: 0.3rem 0.4rem;
  display: flex;
}
.memberClub .diglogPStyle1 .label {
  width: 9em;
}
.memberClub .diglogPStyle1 .psdInput {
  font-size: 16px;
  border: 0;
  flex: 1;
  display: block;
  width: 100%;
}
.memberClub .buttonOk {
  margin-top: 4.5rem;
  color: #FFF;
  font-size: 0.32rem;
  border: 0;
  width: 100%;
  height: 0.88rem;
  line-height: 0.88rem;
  background: #90C4FF;
  border-radius: 0.12rem;
}
.memberClub .userInfo {
  border-bottom: 0.12rem solid #F5F5F5;
  overflow: hidden;
  padding: 0.2rem 0.5rem;
  position: relative;
}
.memberClub .userInfo .level {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -0.2rem;
}
.memberClub .userInfo .level img {
  width: 0.4rem;
  height: 0.4rem;
  display: block;
  margin-right: -0.25rem;
  position: relative;
  z-index: 2;
}
.memberClub .userInfo .level span {
  color: #FFFFFF;
  font-size: 0.12rem;
  width: 1rem;
  height: 0.24rem;
  line-height: 0.24rem;
  padding-left: 0.3rem;
  background: linear-gradient(139deg, #BBDDFF 0%, #AFC2FF 100%);
  border: 1px solid #FFFFFF;
  border-radius: 0.16rem;
  display: block;
  position: relative;
  z-index: 1;
}
.memberClub .userInfo .userHead {
  border-radius: 50%;
  width: 1.2rem;
  height: 1.2rem;
  display: block;
}
.memberClub .userInfo .userName {
  font-size: 0.36rem;
  font-family: Bahnschrift;
  line-height: 0.5rem;
  padding-top: 0.15rem;
}
.memberClub .userInfo .vipBox {
  margin-top: 0.1rem;
}
.memberClub .userInfo .vipBox .vipLevel {
  width: 1rem;
  height: 0.4rem;
  position: relative;
  font-size: 0.24rem;
  font-family: Bahnschrift;
  display: inline-block;
  color: #C1581B;
}
.memberClub .userInfo .vipBox .vipLevel img {
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  width: 100%;
  height: 100%;
}
.memberClub .userInfo .vipBox .vipLevel span {
  position: relative;
  z-index: 11;
  display: block;
  text-align: center;
  line-height: 0.4rem;
  font-weight: bold;
  font-size: 0.26rem;
}
.memberClub .userInfo .vipBox .expireDay {
  color: #666666;
  line-height: 0.35rem;
}
.memberClub .userInfo .vipBox .expireDay .span1 {
  font-size: 0.24rem;
  font-family: Bahnschrift;
  font-weight: normal;
}
.memberClub .userInfo .vipBox .expireDay .span2 {
  margin-left: 0.1rem;
  font-size: 0.24rem;
}
.memberClub .banli {
  padding: 0.5rem 0.3rem;
}
.memberClub .banli .mcTitle {
  font-size: 0.32rem;
  line-height: 0.45rem;
  color: #373737;
  font-weight: bold;
  margin-bottom: 0.25rem;
}
.memberClub .banli .changeStyles {
  border: 1px solid #94C7FC;
  border-radius: 0.12rem;
  text-align: center;
  padding: 0.35rem 0;
  overflow: hidden;
}
.memberClub .banli .changeStyles.checked {
  color: #fff;
  background-image: linear-gradient(135deg, #94c7fc 0%, #ebe2fe 100%);
  position: relative;
}
.memberClub .banli .changeStyles.checked::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 0.6rem;
  height: 0.6rem;
  background: url(../../assets/imgs/icon/member_icon_bottom.png) no-repeat right bottom;
  background-size: contain;
}
.memberClub .banli .changeStyles.checked .dataTitle,
.memberClub .banli .changeStyles.checked .dataPrice,
.memberClub .banli .changeStyles.checked .dataPriceOld {
  color: #FFFFFF;
}
.memberClub .banli .mainBackColor1 p {
  color: #fff;
}
.memberClub .banli .dataTitle {
  color: #666666;
  font-size: 0.28rem;
}
.memberClub .banli .dataPrice {
  color: #90C4FF;
  font-size: 0.44rem;
  font-family: Bahnschrift;
  margin-top: 0.35rem;
}
.memberClub .banli .dataPriceOld {
  color: #999999;
  font-size: 0.32rem;
  font-family: Bahnschrift;
  text-decoration: line-through;
  margin-top: 0.2rem;
}
